import React, { useState, useEffect, useRef } from "react";
import classes from "./SearchableDropdown.module.css";

const SearchableDropdown = ({
  options,
  placeholder,
  onSelect,
  value,
  idForLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const dropdownRef = useRef();

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onSelect(option.value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm("");
    }
  };

  return (
    <div className={classes.dropdown} ref={dropdownRef}>
      <label htmlFor={idForLabel}>{idForLabel}</label>
      <div className={classes.dropdownHeader} onClick={toggleDropdown}>
        {value
          ? options.find((option) => option.value === value)?.label ||
            placeholder
          : placeholder}
      </div>
      {isOpen && (
        <div className={classes.dropdownBody}>
          <input
            id={idForLabel}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
          <ul className={classes.dropdownList}>
            {filteredOptions.map((option) => (
              <li
                key={option.companyID}
                onClick={() => handleSelect(option)}
                className={classes.dropdownItem}
              >
                {option.label}
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className={classes.dropdownItem}>No name found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
