import classes from "./EditUser.module.css";
import Xicon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { setIsEditUserModalShown } from "../../../redux/globalFnSlice";
import { updateUser } from "../../../redux/users/updateUser";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";

export function EditUser() {
  const { selectedData } = useSelector((state) => state.global);
  const { status } = useSelector((state) => state.updateUser);

  const [firstname, setFirstname] = useState(selectedData?.u_firstname);
  const [lastname, setLastname] = useState(selectedData?.u_lastname);
  const dispatch = useDispatch();
  const modalRef = useRef();

  useEffect(() => {
    console.log(selectedData);
    console.log("------------------");
  }, [selectedData]);

  function toggleModal() {
    dispatch(setIsEditUserModalShown());
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateUser({ u_id: selectedData.u_id, u_firstname: firstname, u_lastname: lastname, u_did: selectedData.u_did, u_utid: selectedData.u_utid}));
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = status === "loading";

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>Edit user</h3>
        <span>
          <img src={Xicon} alt="Close modal icon" onClick={toggleModal} />
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formElement}>
          <label htmlFor="firstname">Firstname</label>
          <input type="text" id="firstname" required value={firstname} onChange={(e) => setFirstname(e.target.value)} />
        </div>
        <div className={classes.formElement}>
          <label htmlFor="lastname">Lastname</label>
          <input type="text" id="lastname" required value={lastname} onChange={(e) => setLastname(e.target.value)} />
        </div>

        <button type="submit" className={classes.saveBtn} disabled={isStatusLoading}>
          {isStatusLoading ? <LoadingSpinner /> : "Save"}
        </button>
      </form>
    </div>
  );
}
