export const initialState = {
  isOrganizationsClicked: true,
  isUsersClicked: false,
  isModalsBgShown: false,
  isAddOrgModalShown: false,
  isSuccessMsgShown: false,
  successMsg: "",
  isErrorMsgShown: false,
  errorMsg: "",
  isDeleteOrgModalShown: false,
  selectedData: null,
  selectedId: null,
  isEditMode: false,
  isAddCompanyModalShown: false,
  isDeleteCompanyModalShown: false,
  isDepartmentsModalShown: false,
  isAddUserModalShown: false,
  isEditUserModalShown: false,
  isDeleteUserModalShown: false,
};
