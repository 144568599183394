import classes from "./CompaniesTableElements.module.css";
import editIcon from "../../../images/editIcon.svg";
import deleteIcon from "../../../images/deleteIcon.svg";
import { useDispatch } from "react-redux";
import {
  setIsAddCompanyModalShown,
  setIsDeleteCompanyModalShown,
  setIsDepartmentsModalShown,
  setIsEditMode,
  setSelectedData,
} from "../../../redux/globalFnSlice";

export function CompaniesTableElements({ name, data }) {
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <div
        className={classes.name}
        onClick={() => {
          dispatch(setSelectedData(data));
          dispatch(setIsDepartmentsModalShown());
        }}
      >
        {name}
      </div>
      <div>
        <img
          src={editIcon}
          alt="Edit icon"
          onClick={() => {
            dispatch(setIsAddCompanyModalShown(data));
            dispatch(setIsEditMode(true));
          }}
        />
      </div>
      <div>
        <img src={deleteIcon} alt="Delete icon" onClick={() => dispatch(setIsDeleteCompanyModalShown(data))} />
      </div>
    </div>
  );
}
