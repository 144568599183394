import React, { useState, useEffect } from "react";
import classes from "./Login.module.css";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/login/login";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { resetLoginState } from "../../redux/login/login";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSelector = useSelector((state) => state.user);

  // Function for login form
  useEffect(() => {
    if (userSelector.status === "success") {
      navigate("/organizations");
    } else if (userSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetLoginState());
      }, 2500);
    }
  }, [userSelector.status, navigate]);

  // Function for logging in
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userSelector.status === "idle" || userSelector.status === "error") {
      dispatch(login({ email, password }));
    }
  };

  const isStatusLoading = userSelector.status === "loading";
  const isStatusError = userSelector.status === "error";
  const isBtnDisabled = isStatusLoading;

  return (
    <section className={classes.loginContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={classes.formBg}>
        <img className={classes.background} src={Background} alt="Blue form background" />
        <form className={classes.loginForm} onSubmit={handleSubmit}>
          <h2>Welcome to Washd Drive</h2>
          <div className={classes.inputs}>
            <div className={classes.input}>
              <label htmlFor="email">Email / Username</label>
              <input
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={classes.input}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <button className={classes.loginBtn} type="submit" disabled={isBtnDisabled}>
            LOG IN
          </button>

          <div className={classes.notifications}>
            {isStatusLoading && <LoadingSpinner />}
            {isStatusError && <ErrorMsg msg={userSelector.error} />}
          </div>
        </form>
      </div>
    </section>
  );
}
