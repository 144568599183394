import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const registerUser = createAsyncThunk("user/register", async (data, thunkAPI) => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);
  formData.append("confirm_password", data.confirm_password);
  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("user_type_id", data.user_type_id);
  formData.append("phone_number", data.phone_number);
  formData.append("u_did", 23);

  if (data.avatar) {
    formData.append("avatar", data.avatar);
  }

  if (data.token) {
    formData.append("token", data.token);
  }

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${apiUrl}/register?token=${data.token}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Registration failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const registerSlice = createSlice({
  name: "register",
  initialState: {
    status: "idle",
    error: null,
  },
  reducers: {
    resetRegisterState(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

const persistConfig = {
  key: "register",
  storage,
};

export const { resetRegisterState } = registerSlice.actions;
export default persistReducer(persistConfig, registerSlice.reducer);
