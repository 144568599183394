import { useDispatch, useSelector } from "react-redux";
import classes from "./Users.module.css";
import { setIsAddUserModalShown, setIsDeleteUserModalShown, setIsEditUserModalShown } from "../../redux/globalFnSlice";
import { UsersTableElements } from "../UI/usersTableElements/UsersTableElements";
import { useEffect, useState } from "react";
import { fetchUsers } from "../../redux/users/getUsers";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import { ErrorMsg } from "../errorMsg/ErrorMsg";
import { useStatusEffect } from "../../helperFunctions/customHooks/useStatusEffect";
import { resetUserDeleteStatus } from "../../redux/users/deleteUser";
import { resetUpdateUserStatus } from "../../redux/users/updateUser";

export function Users() {
  const { users, status, error } = useSelector((state) => state.getUsers);
  const deleteUserState = useSelector((state) => state.deleteUser);
  const updateUserState = useSelector((state) => state.updateUser);

  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [startIndex, setStartIndex] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const handleNextPageClick = () => {
    setStartIndex((prev) => Math.min(prev + itemsPerPage, filteredUsers.length - itemsPerPage));
  };

  const handlePrevPageClick = () => {
    setStartIndex((prev) => Math.max(prev - itemsPerPage, 0));
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const resetFilters = () => {
    setSelectedRole("");
    setSelectedDepartment("");
    setSelectedCompany("");
    setFilteredUsers(users);
    setStartIndex(0);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // Populate filter options when users data is successfully fetched.
  useEffect(() => {
    if (status === "success") {
      const uniqueDepartments = Array.from(new Set(users.map((user) => user.d_name)));
      setDepartmentOptions(uniqueDepartments);
      const uniqueCompanies = Array.from(new Set(users.map((user) => user.c_name)));
      setCompanyOptions(uniqueCompanies);
    }
  }, [status, users]);

  // Apply filters when any of the selected filters change.
  useEffect(() => {
    filterUsers();
  }, [users, selectedRole, selectedDepartment, selectedCompany]);

  const filterUsers = () => {
    let filtered = users;
    if (selectedRole) {
      filtered = filtered.filter((user) => user.u_utid === parseInt(selectedRole));
    }
    if (selectedDepartment) {
      filtered = filtered.filter((user) => user.d_name === selectedDepartment);
    }
    if (selectedCompany) {
      filtered = filtered.filter((user) => user.c_name === selectedCompany);
    }
    setFilteredUsers(filtered);
  };

  useStatusEffect({
    status: deleteUserState.status,
    successMessage: "Successfully deleted user",
    errorMessage: deleteUserState.error,
    onSuccess: setIsDeleteUserModalShown,
    onError: setIsDeleteUserModalShown,
    resetStatus: resetUserDeleteStatus,
    reFetch: fetchUsers,
    functionName: "users",
    setCurrentPage: setStartIndex,
    onPage: 1,
  });

  useStatusEffect({
    status: updateUserState.status,
    successMessage: "Successfully updated user",
    errorMessage: updateUserState.error,
    onSuccess: setIsEditUserModalShown,
    onError: setIsEditUserModalShown,
    resetStatus: resetUpdateUserStatus,
    reFetch: fetchUsers,
    functionName: "users",
    setCurrentPage: setStartIndex,
    onPage: 1,
  });

  const isStatusLoading = status === "loading";
  const isStatusError = status === "error";
  const isBtnDisabled = !selectedRole && !selectedDepartment && !selectedCompany;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <h2>Users</h2>
          <button className={classes.addUserBtn} onClick={() => dispatch(setIsAddUserModalShown())}>
            Add user
          </button>
        </div>
      </div>
      <div className={classes.table}>
        <div className={classes.filters}>
          <p>Filter by</p>
          <div className={classes.filter}>
            <label htmlFor="role">Role:</label>
            <select name="role" id="role" className={classes.role} value={selectedRole} onChange={handleRoleChange}>
              <option value="">All roles</option>
              <option value="1">Managers</option>
              <option value="2">Drivers</option>
            </select>
          </div>
          <div className={classes.filter}>
            <label htmlFor="department">Department:</label>
            <select
              name="department"
              id="department"
              className={classes.department}
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">All departments</option>
              {departmentOptions.map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.filter}>
            <label htmlFor="company">Company:</label>
            <select
              name="company"
              id="company"
              className={classes.company}
              value={selectedCompany}
              onChange={handleCompanyChange}
            >
              <option value="">All companies</option>
              {companyOptions.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <button className={classes.resetBtn} onClick={resetFilters} disabled={isBtnDisabled}>
            Reset filters
          </button>
        </div>
        <div className={classes.tableHeader}>
          <div>ID</div>
          <div>Firstname</div>
          <div>Lastname</div>
          <div>Company name</div>
          <div>Department</div>
          <div>Role</div>
          <div>Edit</div>
          <div>Delete</div>
        </div>
        {isStatusLoading && (
          <div className={classes.notificationsDiv}>
            <LoadingSpinner black={true} />
          </div>
        )}

        {isStatusError && (
          <div className={classes.notificationsDiv}>
            <ErrorMsg msg={error} />
          </div>
        )}
        {!isStatusLoading &&
          filteredUsers
            .slice(startIndex, startIndex + itemsPerPage)
            .map((user) => (
              <UsersTableElements
                key={user.u_id}
                id={user.u_id}
                firstname={user.u_firstname}
                lastname={user.u_lastname}
                company_name={user.c_name}
                department={user.d_name}
                role={user.u_utid}
                data={user}
              />
            ))}
        {filteredUsers.length === 0 && !isStatusLoading && (
          <div className={classes.notificationsDiv}>
            <ErrorMsg msg={"No results found"} />
          </div>
        )}
      </div>
      {!isStatusLoading && !isStatusError && (
        <div className={classes.paginationControls}>
          <button className={classes.prevBtn} onClick={handlePrevPageClick} disabled={startIndex === 0}>
            Previous
          </button>
          <button
            className={classes.nextBtn}
            onClick={handleNextPageClick}
            disabled={startIndex + itemsPerPage >= filteredUsers.length}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}
