import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../redux/register/register";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import classes from "./Register.module.css";
import { resetRegisterState } from "../../redux/register/register";

export function Register() {
  const dispatch = useDispatch();
  const registerSelector = useSelector((state) => state.register);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [token, setToken] = useState("superadminmanager");

  useEffect(() => {
    dispatch(resetRegisterState());
  }, []);

  useEffect(() => {
    if (registerSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetRegisterState());
      }, 2000);
    } else if (registerSelector.status === "success") {
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }
  }, [registerSelector.status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("token", token);
    dispatch(registerUser(Object.fromEntries(formData)));
  };

  return (
    <section className={classes.registerContainer}>
      <div className={classes.logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={classes.formBg}>
        <img className={classes.background} src={Background} alt="Blue form background" />

        <form className={classes.registerForm} onSubmit={handleSubmit}>
          <h2>Register to Washd Drive</h2>
          <div className={classes.inputs}>
            <div className={classes.input}>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className={classes.input}>
              <label htmlFor="password">Password</label>
              <input type="password" name="password" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="confirm_password">Confirm Password</label>
              <input type="password" name="confirm_password" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="first_name">First Name</label>
              <input type="text" name="first_name" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="last_name">Last Name</label>
              <input type="text" name="last_name" required />
            </div>
            <div className={classes.input}>
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                required
              />
            </div>
            <button className={classes.registerBtn} type="submit" disabled={registerSelector.status === "loading"}>
              REGISTER
            </button>
          </div>
          <div className={classes.notifications}>
            {registerSelector.status === "loading" && <LoadingSpinner />}
            {registerSelector.status === "error" && <ErrorMsg msg={registerSelector.error} />}
            {registerSelector.status === "success" && (
              <p>Registration successful! Please check your email to verify your account.</p>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}
