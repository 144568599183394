import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const searchItems = createAsyncThunk("search/searchItems", async ({ query }, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const queryParams = new URLSearchParams();
  queryParams.append("query", query);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${apiUrl}/search?${queryParams.toString()}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      let errorText = "";
      try {
        const errorText = await response.text();
        const errorObj = JSON.parse(errorText);
        errorMessage = errorObj.msg || errorMessage;
      } catch (e) {
        errorMessage = errorText || errorMessage;
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Fetching search results failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const searchSlice = createSlice({
  name: "search",
  initialState: {
    query: "", // Initial search query state
    results: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    resetSearchQuery: (state) => {
      state.query = "";
      state.results = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchItems.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(searchItems.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(setSearchQuery, (state, action) => {
        // When search query changes, trigger fetching deliveries
        state.query = action.payload;
        // You may optionally reset other parts of the state related to search results here
        state.results = [];
        state.status = "idle"; // Reset status to idle
        state.error = null; // Reset error to null
      });
  },
});

export const { setSearchQuery, resetSearchQuery } = searchSlice.actions;
export default searchSlice.reducer;
