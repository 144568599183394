import classes from "./Sidebar.module.css";
import logo from "../../images/sidebar/sidebarLogo.svg";
import avatar from "../../images/sidebar/Ellipse 2.png";
import logOutIcon from "../../images/sidebar/logOutIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsOrganizationsClicked,
  setIsUsersClicked,
} from "../../redux/globalFnSlice";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/login/login";
import { setSearchQuery } from "../../redux/search/search";

export function Sidebar() {
  const { isOrganizationsClicked, isUsersClicked } = useSelector(
    (state) => state.global
  );
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <img src={logo} alt="Company logo" className={classes.logo} />
      <div className={classes.menu}>
        <button
          className={
            isOrganizationsClicked ? classes.activeBtn : classes.orgBtn
          }
          onClick={() => {
            dispatch(setIsOrganizationsClicked());
            dispatch(setSearchQuery(""));
            navigate("/organizations");
          }}
        >
          Companies
        </button>
        <button
          className={isUsersClicked ? classes.activeBtn : classes.usersBtn}
          onClick={() => {
            dispatch(setIsUsersClicked());
            dispatch(setSearchQuery(""));
            navigate("/users");
          }}
        >
          Users
        </button>
      </div>
      <div className={classes.userInfo}>
        <div className={classes.content}>
          <div className={classes.avatar}>
            <img src={avatar} alt="Avatar" />
            <span>{userState.firstname}</span>
          </div>
          <img
            src={logOutIcon}
            alt="Log out icon"
            className={classes.logOutIcon}
            onClick={() => dispatch(logoutUser())}
          />
        </div>
      </div>
    </div>
  );
}
