import classes from "./DeleteOrganization.module.css";
import closeIcon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsDeleteOrgModalShown } from "../../../redux/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useRef } from "react";
import { deleteDepartment } from "../../../redux/department/deleteDepartment";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";

export function DeleteOrganization() {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => state.global);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);

  function toggleModal() {
    dispatch(setIsDeleteOrgModalShown());
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = deleteDepartmentState.status === "loading";

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h4>Delete organization </h4>
        <span onClick={toggleModal}>
          <img src={closeIcon} alt="Close modal icon" />
        </span>
      </div>
      <p>
        Are you sure you want to delete <span className={classes.orgName}>{selectedData.d_name} ?</span>
      </p>
      <p>By confirming this your created organization will be deleted. You will not be able to cancel this action.</p>
      <div className={classes.buttons}>
        <button className={classes.cancelBtn} onClick={toggleModal}>
          Cancel
        </button>
        <button
          className={classes.confirmBtn}
          disabled={isStatusLoading}
          onClick={() => dispatch(deleteDepartment({ department_id: selectedData.d_id }))}
        >
          {isStatusLoading ? <LoadingSpinner /> : "Confirm"}
        </button>
      </div>
    </div>
  );
}
