import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { apiUrl } from "../../constants";
import storage from "redux-persist/lib/storage";

export const fetchAvatar = createAsyncThunk("user/fetchAvatar", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;
  const avatar = state.user.avatar;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(`${apiUrl}/avatar?avatar=${avatar}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    return await response.blob(); // Fetch the avatar as a blob
  } catch (error) {
    console.error("Fetching avatar failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${apiUrl}/login_super`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Login failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: "idle",
    error: null,
    token: null,
    refreshToken: null,
    email: null,
    company_id: null,
    avatar: null,
    avatarBlob: null,
    firstname: null,
    lastname: null,
    tokenExpiryTime: null,
  },
  reducers: {
    logoutUser(state) {
      state.token = null;
      state.refreshToken = null;
      state.status = "idle";
      state.error = null;
      state.email = null;
      state.company_id = null;
      state.avatar = null;
      state.avatarBlob = null;
      state.firstname = null;
      state.lastname = null;
      state.tokenExpiryTime = null;
    },
    resetLoginState(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const currentTime = new Date().getTime();
        const tokenExpiryTime = currentTime + 24 * 60 * 60 * 1000; // 1 day

        state.token = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.email = action.payload.email;
        state.company_id = action.payload.company_id;
        state.avatar = action.payload.avatar;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.status = "success";
        state.error = null;
        state.tokenExpiryTime = tokenExpiryTime;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(fetchAvatar.fulfilled, (state, action) => {
        state.avatarBlob = URL.createObjectURL(action.payload);
      })
      .addCase(fetchAvatar.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

const persistConfig = {
  key: "user",
  storage,
};

export const { logoutUser, resetLoginState } = userSlice.actions;
export default persistReducer(persistConfig, userSlice.reducer);
