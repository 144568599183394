import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const updateCompany = createAsyncThunk("company/updateCompany", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const queryParams = new URLSearchParams({
    company_id: data.company_id,
  }).toString();

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${apiUrl}/update_company_super?${queryParams}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Updating Company failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const updateCompanySlice = createSlice({
  name: "company",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    company: null,
  },
  reducers: {
    resetUpdateCompanyStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCompany.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateCompanyStatus } = updateCompanySlice.actions;
export default updateCompanySlice.reducer;
