import classes from "./AddOrganization.module.css";
import Xicon from "../../../images/Xicon.svg";
import { setIsAddOrgModalShown, setIsEditMode } from "../../../redux/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { createDepartment } from "../../../redux/department/createDepartment";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import { updateDepartment } from "../../../redux/department/updateDepartment";

export function AddOrganization() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { isEditMode, selectedData } = useSelector((state) => state.global);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);
  console.log("KURAAAC + " + selectedData)
  const [formData, setFormData] = useState({
    d_name: "",
    d_address: "",
    d_phonenumber: "",
    d_cid: selectedData,
  });

  function toggleModal() {
    dispatch(setIsAddOrgModalShown());
    dispatch(setIsEditMode(false));
  }

  // Handle form input changes.
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // If isEditMode is true then populate the fields with the data from selectedData.
  useEffect(() => {
    if (isEditMode && selectedData) {
      setFormData({
        d_name: selectedData.d_name || "",
        d_phonenumber: selectedData.d_phonenumber || "",
        d_address: selectedData.d_address || "",
      });
    }
  }, [isEditMode, selectedData]);

  // Function for submitting the form.
  function handleSubmit(e) {
    e.preventDefault();
    if (isEditMode) {
      dispatch(
        updateDepartment({
          d_id: selectedData.d_id,
          ...formData,
        })
      );
    } else {
      dispatch(createDepartment(formData));
    }
  }

  // Checks for the submit button disabling.
  const isStatusLoading = createDepartmentState.status === "loading" || updateDepartmentState.status === "loading";
  const isEditModeFields = formData.d_name === "" || formData.d_phonenumber === "" || formData.d_address === "";

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>Add Organization</h3>
        <span onClick={toggleModal}>
          <img src={Xicon} alt="Close modal icon" />
        </span>
      </div>
      <form className={classes.addOrgForm} onSubmit={handleSubmit}>
        <div className={classes.formElement}>
          <label htmlFor="d_name">Name</label>
          <input type="text" id="d_name" value={formData.d_name} required onChange={handleInputChange} />
        </div>
        <div className={classes.formElement}>
          <label htmlFor="d_address">Address</label>
          <input type="text" id="d_address" value={formData.d_address} required onChange={handleInputChange} />
        </div>
        <div className={classes.formElement}>
          <label htmlFor="d_phonenumber">Phone number</label>
          <input
            type="number"
            id="d_phonenumber"
            value={formData.d_phonenumber}
            required
            onChange={handleInputChange}
          />
        </div>

        <button className={classes.saveBtn} type="submit" disabled={isEditModeFields || isStatusLoading}>
          {isStatusLoading ? <LoadingSpinner /> : "Save"}
        </button>
      </form>
    </div>
  );
}
