import classes from "./Organizations.module.css";
import PlusIcon from "../../images/organizations/plusIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAddCompanyModalShown,
  setIsAddOrgModalShown,
  setIsDeleteCompanyModalShown,
  setIsDeleteOrgModalShown,
} from "../../redux/globalFnSlice";
import { useEffect, useState } from "react";
import { resetCreateDepartmentStatus } from "../../redux/department/createDepartment";
import { OrganizationsTableElement } from "../UI/organizationsTableElement/OrganizationsTableElement";
import { fetchDepartments } from "../../redux/department/getDepartments";
import { fetchAllDepartments } from "../../redux/department/getAllDeaprtments";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import { resetDeleteDepartmentStatus } from "../../redux/department/deleteDepartment";
import { useStatusEffect } from "../../helperFunctions/customHooks/useStatusEffect";
import { resetUpdateDepartmentStatus } from "../../redux/department/updateDepartment";
import { resetCreateCompanyState } from "../../redux/company/createCompany";
import { fetchCompanies } from "../../redux/company/getCompanies";
import { resetUpdateCompanyStatus } from "../../redux/company/updateCompany";
import { resetDeleteCompanyStatus } from "../../redux/company/deleteCompany";
import { setSearchQuery } from "../../redux/search/search";
import { Pagination } from "../UI/pagination/Pagination";
import { CompaniesTableElements } from "../UI/companiesTableElements/CompaniesTableElements";

export function Organizations() {
  const dispatch = useDispatch();
  const [isOrgShown, setIsOrgShown] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltered, setIsFiltered] = useState(false);

  // Search query state.
  const searchQuery = useSelector((state) => state.search.query);
  const userState = useSelector((state) => state.user);

  const { selectedData } = useSelector((state) => state.global);


  // Departments states.
  const departmentsState = useSelector((state) => state.getAllDepartments);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);

  // Company states.
  const companiesState = useSelector((state) => state.getCompanies);
  const createCompanyState = useSelector((state) => state.createCompany);
  const updateCompanyState = useSelector((state) => state.updateCompany);
  const deleteCompanyState = useSelector((state) => state.deleteCompany);

  // Getting total pages count for departments.
  const departmentTotalPagesCount = Math.ceil(departmentsState.total_count / 10);
  const filteredDepartmentPages = Math.ceil(departmentsState.filtered_count / 10);

  // Getting total pages count for compaies.
  const companyTotalPagesCount = Math.ceil(companiesState.companies.total_count / 3);
  const filteredCompanyPages = Math.ceil(companiesState.companies.filtered_count / 3);

  useEffect(() => {
    console.log(selectedData)
  }, [selectedData]);

  // Fetch departments when search query or page changes.
  useEffect(() => {
    if (isOrgShown) {
      dispatch(fetchAllDepartments({ department_name: searchQuery, page: currentPage }));
    } else {
      dispatch(fetchCompanies({ page: currentPage, per_page: 3, company_name: searchQuery }));
    }
    if (searchQuery) {
      setIsFiltered(true);
    }
  }, [searchQuery, currentPage, isOrgShown]);

  // Reset to page 1 when the search query changes.
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // Functions for Departments for showing notification messages based on the status.
  useStatusEffect({
    status: createDepartmentState.status,
    successMessage: "Successfully created organization",
    errorMessage: createDepartmentState.error,
    onSuccess: setIsAddOrgModalShown,
    onError: setIsAddOrgModalShown,
    resetStatus: resetCreateDepartmentStatus,
    reFetch: fetchDepartments,
    onPage: departmentTotalPagesCount,
    company_id: selectedData,
    setCurrentPage: setCurrentPage,
    functionName: "departments",
  });

  useStatusEffect({
    status: deleteDepartmentState.status,
    successMessage: "Successfully deleted organization",
    errorMessage: deleteDepartmentState.error,
    onSuccess: setIsDeleteOrgModalShown,
    onError: setIsDeleteOrgModalShown,
    resetStatus: resetDeleteDepartmentStatus,
    reFetch: fetchDepartments,
    onPage: currentPage,
    company_id: selectedData,
    setCurrentPage: setCurrentPage,
    functionName: "departments",
  });

  useStatusEffect({
    status: updateDepartmentState.status,
    successMessage: "Successfully updated organization",
    errorMessage: updateDepartmentState.error,
    onSuccess: setIsAddOrgModalShown,
    onError: setIsAddOrgModalShown,
    resetStatus: resetUpdateDepartmentStatus,
    reFetch: fetchDepartments,
    onPage: currentPage,
    company_id: selectedData,
    setCurrentPage: setCurrentPage,
    functionName: "departments",
  });

  // Functions for Companies for showing notification messages based on the status.
  useStatusEffect({
    status: createCompanyState.status,
    successMessage: "Successfully created company",
    errorMessage: createCompanyState.error,
    onSuccess: setIsAddCompanyModalShown,
    onError: setIsAddCompanyModalShown,
    resetStatus: resetCreateCompanyState,
    reFetch: fetchCompanies,
    onPage: companyTotalPagesCount,
    per_page: 3,
    setCurrentPage: setCurrentPage,
    functionName: "companies",
  });

  useStatusEffect({
    status: updateCompanyState.status,
    successMessage: "Successfully updated company",
    errorMessage: updateCompanyState.error,
    onSuccess: setIsAddCompanyModalShown,
    onError: setIsAddCompanyModalShown,
    resetStatus: resetUpdateCompanyStatus,
    reFetch: fetchCompanies,
    onPage: currentPage,
    per_page: 3,
    setCurrentPage: setCurrentPage,
    functionName: "companies",
  });

  useStatusEffect({
    status: deleteCompanyState.status,
    successMessage: "Successfully deleted company",
    errorMessage: deleteCompanyState.error,
    onSuccess: setIsDeleteCompanyModalShown,
    onError: setIsDeleteCompanyModalShown,
    resetStatus: resetDeleteCompanyStatus,
    reFetch: fetchCompanies,
    onPage: currentPage,
    per_page: 3,
    setCurrentPage: setCurrentPage,
    functionName: "companies",
  });

  function toggleTabs() {
    setIsOrgShown(!isOrgShown);
    dispatch(setSearchQuery(""));
    setIsFiltered(false);
    setCurrentPage(1);
  }

  const isStatusLoading =
    (departmentsState.status === "loading" && isOrgShown) || (companiesState.status === "loading" && !isOrgShown);
  const isStatusError =
    (departmentsState.status === "error" && isOrgShown) || (companiesState.status === "error" && !isOrgShown);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.toggleButtons}>
            <button
              className={isOrgShown ? classes.active : classes.toggleOrgBtn}
              onClick={toggleTabs}
              disabled={isOrgShown}
            >
              Organizations
            </button>
            <button
              className={isOrgShown ? classes.toggleCompaniesBtn : classes.active}
              onClick={toggleTabs}
              disabled={!isOrgShown}
            >
              Companies
            </button>
          </div>
          <div className={classes.buttons}>
            {isOrgShown && (
              <button className={classes.orgBtn} onClick={() => dispatch(setIsAddOrgModalShown())}>
                <img src={PlusIcon} alt="Plus icon" /> Create Department
              </button>
            )}
            {!isOrgShown && (
              <button className={classes.companyBtn} onClick={() => dispatch(setIsAddCompanyModalShown())}>
                <img src={PlusIcon} alt="Plus icon" /> Create Company
              </button>
            )}
          </div>
        </div>
      </div>
      {isOrgShown && (
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div>Organization</div>
            <div>Address</div>
            <div>Contact phone</div>
            <div>Edit</div>
            <div>Delete</div>
          </div>
          {!isStatusLoading &&
            !isStatusError &&
            departmentsState.all_departments.map((department) => (
              <OrganizationsTableElement
                data={department}
                key={department.d_id}
                address={department.d_address}
                organization={department.d_name}
                phone={department.d_phonenumber}
              />
            ))}
        </div>
      )}
      {!isOrgShown && (
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div>Name</div>
            <div>Edit</div>
            <div>Delete</div>
          </div>
          {!isStatusLoading &&
            !isStatusError &&
            companiesState.companies.companies &&
            companiesState.companies.companies.map((company) => (
              <CompaniesTableElements key={company.c_id} name={company.c_name} data={company} />
            ))}
        </div>
      )}
      {isStatusLoading && (
        <div className={classes.notificationsDiv}>
          <LoadingSpinner black={true} />
        </div>
      )}
      {!isStatusLoading && isStatusError && (
        <div className={classes.notificationsDiv}>
          <span className={classes.errorMsg}>{isOrgShown ? departmentsState.error : companiesState.error}</span>
        </div>
      )}
      {(departmentsState.all_departments.length !== 0 || companiesState.companies.length !== 0) &&
        !isStatusError &&
        !isStatusLoading && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={isOrgShown ? departmentTotalPagesCount : companyTotalPagesCount}
            filteredPages={isOrgShown ? filteredDepartmentPages : filteredCompanyPages}
            isFiltered={isFiltered}
          />
        )}
    </div>
  );
}
