import classes from "./AddUser.module.css";
import Xicon from "../../../images/Xicon.svg";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { setIsAddUserModalShown } from "../../../redux/globalFnSlice";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";

export function AddUser() {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const [name, setName] = useState("");

  function toggleModal() {
    dispatch(setIsAddUserModalShown());
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isBtnDisabled = !name;

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>Add Organization</h3>
        <span>
          <img src={Xicon} alt="Close modal icon" onClick={toggleModal} />
        </span>
      </div>
      <form>
        <div className={classes.formElement}>
          <label htmlFor="u_name">Name</label>
          <input type="text" id="u_name" required value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <button type="submit" className={classes.saveBtn} disabled={isBtnDisabled}>
          Send
        </button>
      </form>
    </div>
  );
}
