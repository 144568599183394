import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const updateDepartment = createAsyncThunk("department/updateDepartment", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const queryParams = new URLSearchParams();
  queryParams.append("department_id", data.d_id);

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${apiUrl}/update_department_super?${queryParams.toString()}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Updating department failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const updateDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    department: null,
  },
  reducers: {
    resetUpdateDepartmentStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.department = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateDepartmentStatus } = updateDepartmentSlice.actions;
export default updateDepartmentSlice.reducer;
