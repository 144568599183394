import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (data = {}, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${apiUrl}/get_all_users`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      let errorText = "";
      try {
        const errorText = await response.text();
        const errorObj = JSON.parse(errorText);
        errorMessage = errorObj.error || errorMessage;
      } catch (e) {
        errorMessage = errorText || errorMessage;
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Fetching users failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const fetchUsersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],

    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
  },
  reducers: {
    resetFetchUsersState(state) {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetFetchUsersState } = fetchUsersSlice.actions;
export default fetchUsersSlice.reducer;
