// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessMsg_container__RkcRH {
  padding: 1rem;
  background-color: rgb(97, 247, 115);
  font-weight: 500;
  font-size: 18px;
  border-radius: 8px;
  max-width: 500px;
}

.SuccessMsg_container__RkcRH h4 {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/successMsg/SuccessMsg.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mCAAmC;EACnC,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".container {\r\n  padding: 1rem;\r\n  background-color: rgb(97, 247, 115);\r\n  font-weight: 500;\r\n  font-size: 18px;\r\n  border-radius: 8px;\r\n  max-width: 500px;\r\n}\r\n\r\n.container h4 {\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SuccessMsg_container__RkcRH`
};
export default ___CSS_LOADER_EXPORT___;
