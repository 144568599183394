// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersTableElements_container__G23uv {
  display: flex;
  padding: 5px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  justify-content: space-between;
  width: 90%;
  color: #676c7e;
  line-height: 24px;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.UsersTableElements_container__G23uv > div:first-child {
  width: 50px;
}

.UsersTableElements_container__G23uv > div:not(:first-child):not(:nth-last-child(-n + 3)) {
  width: 120px;
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.UsersTableElements_container__G23uv > div:nth-last-child(-n + 2) {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UsersTableElements_container__G23uv > div:nth-last-child(3) {
  width: 70px;
}

.UsersTableElements_container__G23uv img {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/usersTableElements/UsersTableElements.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,8BAA8B;EAC9B,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\r\n  display: flex;\r\n  padding: 5px 20px;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  justify-content: space-between;\r\n  width: 90%;\r\n  color: #676c7e;\r\n  line-height: 24px;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.container > div:first-child {\r\n  width: 50px;\r\n}\r\n\r\n.container > div:not(:first-child):not(:nth-last-child(-n + 3)) {\r\n  width: 120px;\r\n  display: inline-block;\r\n  white-space: normal;\r\n  word-wrap: break-word;\r\n}\r\n\r\n.container > div:nth-last-child(-n + 2) {\r\n  width: 50px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.container > div:nth-last-child(3) {\r\n  width: 70px;\r\n}\r\n\r\n.container img {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UsersTableElements_container__G23uv`
};
export default ___CSS_LOADER_EXPORT___;
