// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_pagination__gXfpF {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
  width: 95%;
}

.Pagination_nextBtn__y0shf,
.Pagination_prevBtn__fdS3H,
.Pagination_firstPage__ecoFm {
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba(149, 155, 164, 0.32);
  cursor: pointer;
}

.Pagination_nextBtn__y0shf:disabled,
.Pagination_prevBtn__fdS3H:disabled {
  cursor: not-allowed;
}

.Pagination_pagination__gXfpF p {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.Pagination_activePage__8F2D9 {
  background-color: #3082f6;
  color: white;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  border: 1px solid rgba(149, 155, 164, 0.32);
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/pagination/Pagination.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,QAAQ;EACR,UAAU;AACZ;;AAEA;;;EAGE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,2CAA2C;AAC7C","sourcesContent":[".pagination {\r\n  display: flex;\r\n  justify-content: end;\r\n  align-items: center;\r\n  gap: 4px;\r\n  width: 95%;\r\n}\r\n\r\n.nextBtn,\r\n.prevBtn,\r\n.firstPage {\r\n  height: 32px;\r\n  width: 32px;\r\n  border-radius: 10px;\r\n  background-color: white;\r\n  border: 1px solid rgba(149, 155, 164, 0.32);\r\n  cursor: pointer;\r\n}\r\n\r\n.nextBtn:disabled,\r\n.prevBtn:disabled {\r\n  cursor: not-allowed;\r\n}\r\n\r\n.pagination p {\r\n  font-size: 20px;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.activePage {\r\n  background-color: #3082f6;\r\n  color: white;\r\n  height: 32px;\r\n  width: 32px;\r\n  border-radius: 10px;\r\n  border: 1px solid rgba(149, 155, 164, 0.32);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `Pagination_pagination__gXfpF`,
	"nextBtn": `Pagination_nextBtn__y0shf`,
	"prevBtn": `Pagination_prevBtn__fdS3H`,
	"firstPage": `Pagination_firstPage__ecoFm`,
	"activePage": `Pagination_activePage__8F2D9`
};
export default ___CSS_LOADER_EXPORT___;
