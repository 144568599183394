import classes from "./AddCompany.module.css";
import Xicon from "../../../images/Xicon.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCompany } from "../../../redux/company/createCompany";
import {
  setIsAddCompanyModalShown,
  setIsEditMode,
} from "../../../redux/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import { updateCompany } from "../../../redux/company/updateCompany";

export function AddCompany() {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const modalRef = useRef();

  // Redux state variables
  const createCompanyState = useSelector((state) => state.createCompany);
  const updateCompanyState = useSelector((state) => state.updateCompany);
  const { isEditMode, selectedData } = useSelector((state) => state.global);

  function toggleModal() {
    dispatch(setIsAddCompanyModalShown());
    dispatch(setIsEditMode(false));
  }

  // If isEditMode is true then populate the fields with the data from selectedData.
  useEffect(() => {
    if (isEditMode && selectedData) {
      setName(selectedData.c_name);
    }
  }, [isEditMode, selectedData]);

  // Function to handle form submission.
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(updateCompany({ company_id: selectedData.c_id, c_name: name }));
    } else {
      dispatch(
        createCompany({
          c_name: name,
        })
      );
    }
  };

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading =
    createCompanyState.status === "loading" ||
    updateCompanyState.status === "loading";
  const isBtnDisabled = isStatusLoading || name === "";

  const headerText = isEditMode ? "Edit Company" : "Add Company";

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{headerText}</h3>
        <span>
          <img src={Xicon} alt="Close modal icon" onClick={toggleModal} />
        </span>
      </div>
      <form className={classes.addCompanyForm} onSubmit={handleSubmit}>
        <div className={classes.formElement}>
          <label htmlFor="c_name">Name</label>
          <input
            type="text"
            id="c_name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button className={classes.saveBtn} disabled={isBtnDisabled}>
          {isStatusLoading ? <LoadingSpinner /> : "Save"}
        </button>
      </form>
    </div>
  );
}
