import classes from "./OrganizationsTableElement.module.css";
import deleteIcon from "../../../images/deleteIcon.svg";
import editIcon from "../../../images/editIcon.svg";
import { useDispatch } from "react-redux";
import { setIsAddOrgModalShown, setIsDeleteOrgModalShown, setIsEditMode } from "../../../redux/globalFnSlice";

export function OrganizationsTableElement({ organization, address, phone, data }) {
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <div className={classes.tableElement}>{organization}</div>
      <div className={classes.tableElement}>{address}</div>
      <div className={classes.tableElement}>{phone}</div>
      <div className={classes.tableElement}>
        <img
          src={editIcon}
          alt="Edit icon"
          onClick={() => {
            dispatch(setIsAddOrgModalShown(data));
            dispatch(setIsEditMode(true));
          }}
        />
      </div>
      <div className={classes.tableElement}>
        <img src={deleteIcon} alt="Delete icon" onClick={() => dispatch(setIsDeleteOrgModalShown(data))} />
      </div>
    </div>
  );
}
