// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditUser_container__vGsGA {
  width: 400px;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.EditUser_header__v3kb1 {
  display: flex;
  justify-content: space-between;
}

.EditUser_header__v3kb1 h3 {
  margin: 0;
}

.EditUser_header__v3kb1 span {
  cursor: pointer;
}

.EditUser_formElement__lyYJe {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 8px 16px;
}

.EditUser_formElement__lyYJe input {
  border: none;
  background-color: transparent;
}

.EditUser_formElement__lyYJe input:focus {
  outline: none;
}

.EditUser_saveBtn__sopgy {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditUser_saveBtn__sopgy:disabled {
  background-color: #8cb6f1;
  cursor: not-allowed;
}

.EditUser_container__vGsGA form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/editUser/EditUser.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".container {\r\n  width: 400px;\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.header h3 {\r\n  margin: 0;\r\n}\r\n\r\n.header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.formElement {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  border: 1px solid #ededed;\r\n  border-radius: 12px;\r\n  padding: 8px 16px;\r\n}\r\n\r\n.formElement input {\r\n  border: none;\r\n  background-color: transparent;\r\n}\r\n\r\n.formElement input:focus {\r\n  outline: none;\r\n}\r\n\r\n.saveBtn {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.saveBtn:disabled {\r\n  background-color: #8cb6f1;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.container form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EditUser_container__vGsGA`,
	"header": `EditUser_header__v3kb1`,
	"formElement": `EditUser_formElement__lyYJe`,
	"saveBtn": `EditUser_saveBtn__sopgy`
};
export default ___CSS_LOADER_EXPORT___;
