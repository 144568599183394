import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const updateUser = createAsyncThunk("user/updateUser", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    const response = await fetch(`${apiUrl}/update_driver_super`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Updating user failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const updateUserSlice = createSlice({
  name: "user",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    user: null,
  },
  reducers: {
    resetUpdateUserStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateUserStatus } = updateUserSlice.actions;
export default updateUserSlice.reducer;
