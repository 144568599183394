import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const createCompany = createAsyncThunk("company/createCompany", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const companyData = {
    c_name: data.c_name,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(companyData),
  };

  try {
    const response = await fetch(`${apiUrl}/create_company`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Company creation failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const companySlice = createSlice({
  name: "company",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    company: null,
  },
  reducers: {
    resetCreateCompanyState(state) {
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCompany.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        console.log(action);
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetCreateCompanyState } = companySlice.actions;
export default companySlice.reducer;
