import { useDispatch, useSelector } from "react-redux";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import classes from "./DepartmentsModal.module.css";
import { useEffect, useRef, useState } from "react";
import { 
  setIsDepartmentsModalShown, 
  setIsAddOrgModalShown, 
  setIsDeleteOrgModalShown, 
  setIsEditMode 
} from "../../../redux/globalFnSlice";
import closeIcon from "../../../images/Xicon.svg";
import editIcon from "../../../images/editIcon.svg";
import deleteIcon from "../../../images/deleteIcon.svg";
import plusIcon from "../../../images/organizations/plusIcon.svg"; // Add the plus icon import
import { fetchDepartments } from "../../../redux/department/getDepartments";
import { Pagination } from "../../UI/pagination/Pagination";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";

export function DepartmentsModal() {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentId, setCurrentId] = useState(null);
  const { selectedData } = useSelector((state) => state.global);
  const departmentsState = useSelector((state) => state.getDepartments);

  const departmentTotalPagesCount = Math.ceil(departmentsState.total_count / 10);
  const filteredDepartmentPages = Math.ceil(departmentsState.filtered_count / 10);

  useEffect(() => {
    if (selectedData.c_id) {
      dispatch(fetchDepartments({ company_id: selectedData.c_id, page: currentPage }));
    }
  }, [selectedData.c_id, currentPage]);

  useEffect(() => {
    setCurrentId(selectedData.c_id);
  }, [selectedData.c_id, selectedData]);

  function closeModal() {
    dispatch(setIsDepartmentsModalShown());
  }

  useEscapeKeyClose(closeModal);
  useClickOutsideClose(modalRef, closeModal);

  const isStatusLoading = departmentsState.status === "loading";
  const isStatusError = departmentsState.status === "error";

  const handleEditClick = (department) => {
    dispatch(setIsDepartmentsModalShown()); // Close departments modal
    dispatch(setIsAddOrgModalShown(department)); // Open edit modal and pass department data
    dispatch(setIsEditMode(true)); // Set the edit mode to true
  };

  const handleDeleteClick = (department) => {
    dispatch(setIsDepartmentsModalShown()); // Close departments modal
    dispatch(setIsDeleteOrgModalShown(department)); // Open delete modal and pass department data
  };

  const handleCreateClick = () => {
    dispatch(setIsDepartmentsModalShown()); // Close departments modal
    dispatch(setIsAddOrgModalShown(currentId)); // Open add department modal
    dispatch(setIsEditMode(false)); // Set the edit mode to false
  };

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h4>
          Departments for <p>{selectedData.c_name}</p>
        </h4>
        <span onClick={closeModal}>
          <img src={closeIcon} alt="Close modal icon" />
        </span>
      </div>
      {/* Add Create Department button */}
      <div className={classes.createButtonContainer}>
        <button className={classes.orgBtn} onClick={handleCreateClick}>
          <img src={plusIcon} alt="Plus icon" /> Create Department
        </button>
      </div>
      <div className={classes.tableHeader}>
        <div>Name</div>
        <div>Address</div>
        <div>Phone</div>
        <div>Edit</div>
        <div>Delete</div>
      </div>
      {isStatusLoading && (
        <div className={classes.notificationDiv}>
          <LoadingSpinner black={true} />
        </div>
      )}
      {!isStatusLoading && isStatusError && <div className={classes.error}>{departmentsState.error}</div>}
      <div className={classes.table}>
        {!isStatusLoading &&
          !isStatusError &&
          departmentsState.departments.map((department) => (
            <div className={classes.tableContent} key={department.d_id}>
              <div>{department.d_name}</div>
              <div>{department.d_address}</div>
              <div>{department.d_phonenumber}</div>
              <div className={classes.tableElement}>
                <img
                  src={editIcon}
                  alt="Edit icon"
                  onClick={() => handleEditClick(department)}
                />
              </div>
              <div className={classes.tableElement}>
                <img
                  src={deleteIcon}
                  alt="Delete icon"
                  onClick={() => handleDeleteClick(department)}
                />
              </div>
            </div>
          ))}
      </div>
      {departmentsState.departments.length !== 0 && !isStatusError && !isStatusLoading && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={departmentTotalPagesCount}
          filteredPages={filteredDepartmentPages}
        />
      )}
    </div>
  );
}
