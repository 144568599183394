// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteUser_container__57f4q {
  width: 400px;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.DeleteUser_header__5RpTQ {
  display: flex;
  justify-content: space-between;
}

.DeleteUser_header__5RpTQ h3 {
  margin: 0;
}

.DeleteUser_header__5RpTQ span {
  cursor: pointer;
}

.DeleteUser_saveBtn__HdNLj,
.DeleteUser_cancelBtn__ON6vF {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DeleteUser_saveBtn__HdNLj:disabled {
  background-color: #8cb6f1;
  cursor: not-allowed;
}

.DeleteUser_cancelBtn__ON6vF {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.DeleteUser_user__F5aqR {
  color: #3082f6;
  font-weight: 500;
  margin-left: 5px;
}

.DeleteUser_buttons__BI1IR {
  display: flex;
  gap: 10px;
}

.DeleteUser_content__qEsJr p {
  margin: 0 0 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/deleteUser/DeleteUser.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\r\n  width: 400px;\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.header h3 {\r\n  margin: 0;\r\n}\r\n\r\n.header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.saveBtn,\r\n.cancelBtn {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.saveBtn:disabled {\r\n  background-color: #8cb6f1;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.cancelBtn {\r\n  background-color: white;\r\n  color: black;\r\n  border: 1px solid black;\r\n}\r\n\r\n.user {\r\n  color: #3082f6;\r\n  font-weight: 500;\r\n  margin-left: 5px;\r\n}\r\n\r\n.buttons {\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n\r\n.content p {\r\n  margin: 0 0 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DeleteUser_container__57f4q`,
	"header": `DeleteUser_header__5RpTQ`,
	"saveBtn": `DeleteUser_saveBtn__HdNLj`,
	"cancelBtn": `DeleteUser_cancelBtn__ON6vF`,
	"user": `DeleteUser_user__F5aqR`,
	"buttons": `DeleteUser_buttons__BI1IR`,
	"content": `DeleteUser_content__qEsJr`
};
export default ___CSS_LOADER_EXPORT___;
