// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompaniesTableElements_container__wcy0A {
  display: flex;
  padding: 5px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  justify-content: space-between;
  width: 90%;
  color: #676c7e;
  line-height: 24px;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.CompaniesTableElements_container__wcy0A img {
  cursor: pointer;
}

.CompaniesTableElements_container__wcy0A > div {
  width: 120px;
  padding: 0px 5px;
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.CompaniesTableElements_name__28576 {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/companiesTableElements/CompaniesTableElements.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,8BAA8B;EAC9B,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\r\n  display: flex;\r\n  padding: 5px 20px;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  justify-content: space-between;\r\n  width: 90%;\r\n  color: #676c7e;\r\n  line-height: 24px;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.container img {\r\n  cursor: pointer;\r\n}\r\n\r\n.container > div {\r\n  width: 120px;\r\n  padding: 0px 5px;\r\n  display: inline-block;\r\n  white-space: normal;\r\n  word-wrap: break-word;\r\n}\r\n\r\n.name {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CompaniesTableElements_container__wcy0A`,
	"name": `CompaniesTableElements_name__28576`
};
export default ___CSS_LOADER_EXPORT___;
