// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_container__Knl8S {
  background-color: #3082f6;
  width: 25%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Sidebar_logo__NP9ho {
  padding: 1.5rem 0;
}

.Sidebar_menu__3-oPo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 85%;
}

.Sidebar_orgBtn__WYAzH,
.Sidebar_usersBtn__SDDh- {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.Sidebar_activeBtn__jljNv {
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  background-color: #7aa9ed;
}

.Sidebar_avatar__WsdnR {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
  font-weight: 500;
}

.Sidebar_userInfo__FTDHG {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
}

.Sidebar_content__g92PC {
  height: 100%;
  align-items: center;
  width: 85%;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,eAAe;EACf,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".container {\r\n  background-color: #3082f6;\r\n  width: 25%;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.logo {\r\n  padding: 1.5rem 0;\r\n}\r\n\r\n.menu {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 24px;\r\n  width: 85%;\r\n}\r\n\r\n.orgBtn,\r\n.usersBtn {\r\n  background-color: transparent;\r\n  border: none;\r\n  color: white;\r\n  font-size: 1rem;\r\n  cursor: pointer;\r\n  width: 100%;\r\n  padding: 10px;\r\n}\r\n\r\n.activeBtn {\r\n  border: none;\r\n  border-radius: 10px;\r\n  font-size: 1rem;\r\n  color: white;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 10px;\r\n  cursor: pointer;\r\n  padding: 10px;\r\n  background-color: #7aa9ed;\r\n}\r\n\r\n.avatar {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n  color: white;\r\n  font-weight: 500;\r\n}\r\n\r\n.userInfo {\r\n  width: 100%;\r\n  height: 100px;\r\n  display: flex;\r\n  justify-content: center;\r\n  border-top: 1px solid white;\r\n}\r\n\r\n.content {\r\n  height: 100%;\r\n  align-items: center;\r\n  width: 85%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__Knl8S`,
	"logo": `Sidebar_logo__NP9ho`,
	"menu": `Sidebar_menu__3-oPo`,
	"orgBtn": `Sidebar_orgBtn__WYAzH`,
	"usersBtn": `Sidebar_usersBtn__SDDh-`,
	"activeBtn": `Sidebar_activeBtn__jljNv`,
	"avatar": `Sidebar_avatar__WsdnR`,
	"userInfo": `Sidebar_userInfo__FTDHG`,
	"content": `Sidebar_content__g92PC`
};
export default ___CSS_LOADER_EXPORT___;
