// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddCompany_container__ZTWRq {
  width: 400px;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.AddCompany_header__Klbeb {
  display: flex;
  justify-content: space-between;
}

.AddCompany_header__Klbeb h3 {
  margin: 0;
}

.AddCompany_header__Klbeb span {
  cursor: pointer;
}

.AddCompany_addCompanyForm__YYVCT {
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: #676c7e;
  overflow-y: auto;
}

.AddCompany_formElement__lUhX9 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 8px 16px;
}

.AddCompany_addCompanyForm__YYVCT input,
.AddCompany_addCompanyForm__YYVCT select {
  border: none;
  background-color: transparent;
}

.AddCompany_addCompanyForm__YYVCT select:focus,
.AddCompany_addCompanyForm__YYVCT input:focus {
  outline: none;
}

.AddCompany_saveBtn__-T8PN {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddCompany_saveBtn__-T8PN:disabled {
  background-color: #8cb6f1;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/addCompany/AddCompany.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".container {\r\n  width: 400px;\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.header h3 {\r\n  margin: 0;\r\n}\r\n\r\n.header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.addCompanyForm {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n  color: #676c7e;\r\n  overflow-y: auto;\r\n}\r\n\r\n.formElement {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  border: 1px solid #ededed;\r\n  border-radius: 12px;\r\n  padding: 8px 16px;\r\n}\r\n\r\n.addCompanyForm input,\r\n.addCompanyForm select {\r\n  border: none;\r\n  background-color: transparent;\r\n}\r\n\r\n.addCompanyForm select:focus,\r\n.addCompanyForm input:focus {\r\n  outline: none;\r\n}\r\n\r\n.saveBtn {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.saveBtn:disabled {\r\n  background-color: #8cb6f1;\r\n  cursor: not-allowed;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddCompany_container__ZTWRq`,
	"header": `AddCompany_header__Klbeb`,
	"addCompanyForm": `AddCompany_addCompanyForm__YYVCT`,
	"formElement": `AddCompany_formElement__lUhX9`,
	"saveBtn": `AddCompany_saveBtn__-T8PN`
};
export default ___CSS_LOADER_EXPORT___;
