// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddManager_container__qritY {
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}

.AddManager_header__XPO5e {
  display: flex;
  justify-content: space-between;
}

.AddManager_header__XPO5e h3 {
  margin: 0;
}

.AddManager_header__XPO5e span {
  cursor: pointer;
}

.AddManager_formElement__Zb89L {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 8px 16px;
  flex: 1 1;
}

.AddManager_formElement__Zb89L label {
  color: rgba(27, 31, 59, 0.65);
}

.AddManager_formElement__Zb89L input,
.AddManager_formElement__Zb89L select {
  border: none;
  color: rgba(164, 165, 177, 1);
}

.AddManager_form__7c7kQ {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.AddManager_form__7c7kQ button {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddManager_form__7c7kQ button:disabled {
  background-color: #699ee9;
  cursor: not-allowed;
}

.AddManager_elements__EphEq {
  display: flex;
  gap: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/addManager/AddManager.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,SAAO;AACT;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".container {\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n  width: 50%;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.header h3 {\r\n  margin: 0;\r\n}\r\n\r\n.header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.formElement {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  border: 1px solid #ededed;\r\n  border-radius: 12px;\r\n  padding: 8px 16px;\r\n  flex: 1;\r\n}\r\n\r\n.formElement label {\r\n  color: rgba(27, 31, 59, 0.65);\r\n}\r\n\r\n.formElement input,\r\n.formElement select {\r\n  border: none;\r\n  color: rgba(164, 165, 177, 1);\r\n}\r\n\r\n.form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 2rem;\r\n}\r\n\r\n.form button {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.form button:disabled {\r\n  background-color: #699ee9;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.elements {\r\n  display: flex;\r\n  gap: 2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddManager_container__qritY`,
	"header": `AddManager_header__XPO5e`,
	"formElement": `AddManager_formElement__Zb89L`,
	"form": `AddManager_form__7c7kQ`,
	"elements": `AddManager_elements__EphEq`
};
export default ___CSS_LOADER_EXPORT___;
