import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsEditMode, setIsErrorMsgShown, setIsSuccessMsgShown } from "../../redux/globalFnSlice";

// Custom Hook to handle status effects.
export function useStatusEffect({
  status,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
  resetStatus,
  reFetch,
  onPage,
  setCurrentPage,
  company_id,
  per_page,
  functionName,
}) {
  const dispatch = useDispatch();

  // Search query state.
  const searchQuery = useSelector((state) => state.search.query);

  useEffect(() => {
    if (status === "success") {
      if (functionName === "departments") {
        dispatch(reFetch({ department_name: searchQuery, page: onPage, company_id: company_id }));
      } else if (functionName === "companies") {
        dispatch(reFetch({ page: onPage, per_page: per_page, company_name: searchQuery }));
      } else {
        dispatch(reFetch());
      }
      setCurrentPage(onPage);
      dispatch(onSuccess());
      dispatch(setIsSuccessMsgShown(successMessage));
      dispatch(setIsEditMode());
      setTimeout(() => {
        dispatch(resetStatus());
        dispatch(setIsSuccessMsgShown(""));
      }, 2000);
    } else if (status === "error") {
      dispatch(onError());
      dispatch(setIsErrorMsgShown(errorMessage));
      dispatch(setIsEditMode());
      setTimeout(() => {
        dispatch(resetStatus());
        dispatch(setIsErrorMsgShown(""));
      }, 2000);
    }
  }, [status]);
}
